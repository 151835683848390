<template>
  <div id="field-container">
    <div id="divfield">
      <input
        :id="'field-' + name"
        :class="'field field-' + name"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        v-model="value"
        @input="onInput"
      />
    </div>
    <div id="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'custom-input',
  props: ['label', 'value', 'type', 'placeholder'],
  emits: ['input'],
  data() {
    return {
      error: '',
    };
  },
  methods: {
    onInput(event) {
      const value = event.target.value;

      /*
      // PER ORA NON GESTISCO GLI ERRORI LATO CUSTOM INPUT
      this.error = '';

      if (!value || value.trim() == '') {
        this.error = 'Value should not be empty';
        return;
      }
      */
      this.$emit('input', value);
    },
  },
  computed: {
    name() {
      return this.label.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
input {
  max-height: none;
  width: 80%;
  padding: 15px 20px;
  border: none;
  border-radius: 100px;
  font-size: 16px;
  color: #333;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

input::placeholder {
  color: #666;
}

input::first-letter {
  text-transform: uppercase;
}

#field-container {
  margin-top: 10px;
}

#label {
  width: 80%;
  text-align: left;
  /* padding: 10px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.field {
  width: 80%;
  padding: 10px 20px;
  border: none;
}
</style>
