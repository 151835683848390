<template>
  <div id="resend" v-if="step === 0">
    <h2>Inserisci la tua email di registrazione.</h2>
    <form @submit.prevent="resend">
      <div id="username_div">
        <custom-input
          label="email"
          :value="email"
          type="text"
          @input="(val) => (email = val)"
        />
        <div
          id="emailErr"
          class="error"
          v-html="emailErr"
          v-if="emailErr != ''"
        ></div>
      </div>
      <div id="button">
        <button :disabled="loading">Invia nuovo codice di attivazione</button>
      </div>
    </form>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'resend_code',
  components: { customInput },
  data() {
    return {
      email: '',
      emailErr: '',
      loading: false,
      step: 0,
    };
  },
  methods: {
    resend() {
      this.loading = true;
      this.emailErr = '';
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
      };

      if (!validateEmail(this.email)) {
        this.loading = false;
        this.emailErr = 'Email non valida.';
        return;
      }

      this.axios
        .post(this.api.resendCode, { email: this.email })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: 'Signup check code',
            params: { from: 'resend' },
          });
        })
        .catch((response) => {
          this.emailErr = response.response.data.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.error {
  font-size: 80%;
  color: var(--var-primary-color);
}
</style>
